import AppBar from 'components/UI/AppBar';
import HamburgerButton from 'components/UI/HamburgerButton';
import SearchBar from 'containers/SearchBar';
import DarkModeToggle from 'containers/DarkModeToggle';
import TheUser from 'containers/TheUser';
import Avatar from 'components/Avatar/index.tsx';
import {BsFacebook,BsInstagram,BsWhatsapp,BsTelegram} from 'react-icons/bs'

const BurgerHeader = ({ openMenu }) => (
  <>
    <AppBar>
      <HamburgerButton onClick={openMenu} />
      
      
      <div className='sticky-bar-widgets-container'>
        <SearchBar id='mobile' />
        <div 
        onClick={() => {}}
        className="
          p-4
          md:py-1
          md:px-2
          border-neutral-200 
          flex 
          flex-row 
          items-center 
          gap-3 
          rounded-full 
          cursor-pointer 
          hover:shadow-md 
          transition
          "
        >
            <Avatar icon={BsFacebook} href='https://www.facebook.com/profile.php?id=100074353405349'  />
        </div>
        <div 
        onClick={() => {}}
        className="
          p-4
          md:py-1
          md:px-2
          
          border-neutral-200 
          flex 
          flex-row 
          items-center 
          gap-3 
          rounded-full 
          cursor-pointer 
          hover:shadow-md 
          transition
          "
        >
          
          <div className="">
            <Avatar icon={BsInstagram}  href='https://www.instagram.com/boutiq20thplus' />
          </div>
        </div>

        <div 
        onClick={() => {}}
        className="
          p-4
          md:py-1
          md:px-2
          
          border-neutral-200 
          flex 
          flex-row 
          items-center 
          gap-3 
          rounded-full 
          cursor-pointer 
          hover:shadow-md 
          transition
          "
        >
          
          <div className="">
            <Avatar icon={BsWhatsapp}  href='http://api.whatsapp.com/send?phone=8618871456039'/>
          </div>
        </div>
        {/* <div 
        onClick={() => {}}
        className="
          p-4
          md:py-1
          md:px-2
          
          border-neutral-200 
          flex 
          flex-row 
          items-center 
          gap-3 
          rounded-full 
          cursor-pointer 
          hover:shadow-md 
          transition
          "
        >
          <div className="">
            <Avatar icon={BsTelegram}  href='https://www.facebook.com'/>
          </div>
        </div> */}
    </div>
    </AppBar>
    <style jsx>{`
      .sticky-bar-widgets-container {
        display: flex;
        align-items: center;
      }

      .sticky-bar-widgets-container > :global(*:not(:first-child)) {
        margin-left: 8px;
      }
    `}</style>
  </>
);

export default BurgerHeader;
