import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import { IconType } from "react-icons";
interface AvatarProps {
  icon: IconType,
  size: number,
  href: string
}


const Avatar: React.FC<AvatarProps> = ({ icon: Icon, size, href }) => {
  const router = useRouter();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(href, "_blank");
  }
  
  
  return (
      <a onClick={handleClick}>
      <Icon size={26} />
      </a>
  );
}
 
export default Avatar;